body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
  height: 5px;
  border-radius: 10px;
  
}

::-webkit-scrollbar-thumb
{
	background-color: #AAA;
	border-radius: 10px;
	background-image: -webkit-linear-gradient(90deg,
	                                          rgba(0, 0, 0, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(0, 0, 0, .2) 50%,
											  rgba(0, 0, 0, .2) 75%,
											  transparent 75%,
											  transparent)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.logoHV {
  height: 100%;
  color: yellow;
  padding: 10px;
  font-size: 35px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.titleLogo{
  color: rgb(88, 89, 91);
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  display: contents;
}

.logos {
  height: 100%;
  color: yellow;
  padding: 10px;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 48px;
  color: #58595b;
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.loading_page{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 100vh;
}

.subTitle{
font-style: normal;
font-weight: normal;
font-size: 18px;
align-items: flex-end;
color: #050505;

}
.suggest-search {
  background-color: #b5b7bb;
  padding: 8px 11px;
}

.title-search {
  font-size: 20px;
  line-height: 16px;
}

.search-item {
  display: flex;
}

.name-item-search {
  width: 45%;
  margin-left: 21px;
}

.icon-item-search{
  margin-left: 5px;
}

.date-item-search {
  width: 40%;
  float: right;
  display: flex;
  justify-content: flex-end;
}


.groupTitle{
  font-size: 18px;
  color:#050505;
  margin-top:10px
}
ul,li {
  list-style: none;
}
.iconMoveTask:hover{
  display: block;
}

.my_task{
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 24px 10px 24px;
}

.react-calendar-timeline .rct-today{
  color: red;
}
