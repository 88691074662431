body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  #components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }
  
  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
    background: #fff;
    /* padding: 0px !important; */
  }
  
  .logoHV {
    height: 100%;
    color: yellow;
    padding: 10px;
    font-size: 35px;
    /* display: flex; */
    justify-content: center;
    align-items: center;
  }
  
  .titleLogo{
    color: rgb(88, 89, 91);
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    display: contents;
  }
  
  .logos {
    height: 100%;
    color: yellow;
    padding: 10px;
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo {
    height: 48px;
    color: #58595b;
    display: flex;
    align-items: center;
    padding-left: 25px;
  }
  
  .loading_page{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    height: 100vh;
  }
  
  .subTitle{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  align-items: flex-end;
  color: #050505;
  
  }
  .suggest-search {
    background-color: #b5b7bb;
    padding: 8px 11px;
  }
  
  .title-search {
    font-size: 20px;
    line-height: 16px;
  }
  
  .search-item {
    display: flex;
  }
  
  .name-item-search {
    width: 45%;
    margin-left: 21px;
  }
  
  .icon-item-search{
    margin-left: 5px;
  }
  
  .date-item-search {
    width: 40%;
    float: right;
    display: flex;
    justify-content: flex-end;
  }
  
  
  .groupTitle{
    font-size: 18px;
    color:#050505;
    margin-top:10px
  }
  
  /***************************/
  .folderList{
      display: flex;
      padding-left: 29px;
      padding-right: 29px;
      padding-top:0px;
      /* padding-bottom: 30px; */
  }
  .folderItem .item{
    padding-right: 24px;
    font-size: 12px;
    color: #605E5C;
    text-align: center;
    display: none;
  }
  .folderItem .img{
    width: 80px;
    height: 80px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('../images/icons/folder_no_file.svg');
  
  }
  
  
  .folderItem .title{
    font-size: 14px;
    color: #050505;
    text-align: center;
    display: block;
  }
  
  .folderItem .date{
    font-size: 12px;
    color: #605E5C;
    text-align: center;
    display: block;
  }
  
  
  /***************************/
  .fileFolderHeader{
    display: none;
  }
  .groupFolderRow .fileFolderHeader{
    display: block;
  }
  
  .groupFolderRow .folderList{
    padding-left: 29px;
    padding-right: 29px;
    padding-top:0px;
    width: 100%;
    display: block;
  }
  .groupFolderRow .folderItem{
  padding-right: 24px;
  display: flex;
  border-bottom:1px solid #F6F6F6;
  height: 43px;
  align-items: center;
  }
  
  .groupFolderRow .fileFolderHeader .item {
    font-weight: bold;
    font-size: 14px !important;
    color:#050505 !important;
  }
  
  .groupFolderRow .folderItem .item{
    flex:3;
    display: block;
  }
  
  .groupFolderRow .folderItem .img{
    width: 20px;
    height: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px;
    
  }
  
  
  .groupFolderRow .folderItem .title{
  flex:10;
  text-align: left;
  padding-left: 8px;
  display: flex;
  }
  
  
  
  /***************************/
  .fileList{
    display: flex;
    padding-left: 29px;
    padding-right: 29px;
    padding-top:0px;
    flex-wrap: wrap;
  }
  .fileItem{
    width: 125px;
    height: 125px;
    margin-right: 10px;
  }

  .fileItem:hover{
    background: #F6F6F6;
  }

  .fileItem:hover + .title{
    background: rgba(222, 222, 222, 0.6);
  }
  
  .fileItem .item {
    font-size: 12px;
    color: #605E5C;
    text-align: center;
    display: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px
  }
  
  .fileItem .img{
  width: 80px;
  height: 80px;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  }
  
  
  .fileItem .img_folder{
  background-image: url('../images/icons/folder_no_file.svg');
  }

  .fileItem .img_folder_has_file{
    background-image: url('../images/icons/folder_with_files.svg');
  }
  
  .fileItem .img_docx{
  background-image: url('../images/icons/word.svg');
  }

  .fileItem .img_doc{
    background-image: url('../images/icons/word.svg');
  }
  
  .fileItem .img_excel{
  background-image: url('../images/icons/ex.svg');
  }

  .fileItem .img_xlsx{
  background-image: url('../images/icons/ex.svg');
  }

  .fileItem .img_xls{
    background-image: url('../images/icons/ex.svg');
  }

  .fileItem .img_csv{
    background-image: url('../images/icons/ex.svg');
  }

  .fileItem .img_pdf{
    background-image: url('../images/icons/pdf.svg');
  }

  .fileItem .img_pptx{
    background-image: url('../images/icons/PP.svg');
  }

  .fileItem .img_ppt{
    background-image: url('../images/icons/PP.svg');
  }

  .fileItem .img_txt{
    background-image: url('../images/icons/txt.svg');
  }
  
  .img_detail_txt{
    width: 65px;
    height: 65px;
    background-image: url('../images/icons/txt.svg');
  }

  .img_detail_docx{
    width: 65px;
    height: 65px;
    background-image: url('../images/icons/word.svg');
  }

  .img_detail_doc{
    width: 65px;
    height: 65px;
    background-image: url('../images/icons/word.svg');
  }

  .img_detail_csv{
    width: 65px;
    height: 65px;
    background-image: url('../images/icons/ex.svg');
  }

  .img_detail_xls{
    width: 65px;
    height: 65px;
    background-image: url('../images/icons/ex.svg');
  }

  .img_detail_xlsx{
    width: 65px;
    height: 65px;
    background-image: url('../images/icons/ex.svg');
  }

  .img_detail_ppt{
    width: 65px;
    height: 65px;
    background-image: url('../images/icons/PP.svg');
  }

  .img_detail_pptx{
    width: 65px;
    height: 65px;
    background-image: url('../images/icons/PP.svg');
  }

  .img_detail_pdf{
    width: 65px;
    height: 65px;
    background-image: url('../images/icons/pdf.svg');
  }
  
  .fileItem .title{
  font-size: 14px;
  color: #050505;
  display: block;
  }
  
  .fileItem .date{
  display: block;
  }
  
  
  
  /***************************/
  .fileListHeader{
    display: none;
  }
  .groupFileRow .fileListHeader{
    display: block;
  }
  
  .groupFileRow .fileList{
    padding-left: 29px;
    padding-right: 29px;
    padding-top:0px;
    width: 100%;
    display: block;
  }
  .groupFileRow .fileItem{
  padding-right: 24px;
  display: flex;
  border-bottom:1px solid #F6F6F6;
  height: 43px;
  align-items: center;
  }
  
  .groupFileRow .fileListHeader .item {
    font-weight: bold;
    font-size: 14px !important;
    color:#050505 !important;
  }
  
  .groupFileRow .fileItem .item{
    flex:3;
    display: block;
  }
  
  .groupFileRow .fileItem .img{
    width: 20px;
    height: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px;
    
  }
  
  
  .groupFileRow .fileItem .title{
    flex:9;
    text-align: left;
    padding-left: 8px;
    display: flex;
  }
  
  .ant-collapse-item{
    position: relative;
  }
  
  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
    padding-top: 0px !important;
    padding-bottom:0px !important;
  }
  
  .removeboder .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    width: 0px;
  } 
  
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;
  }
  
  
  .tableListFile{
  margin-bottom:10px;
  }
  
  .fileList{
    margin-bottom:10px;
  }
  
  .tableListFile .img{
    width: 20px;
    height: 20px;
    background-size: 20px;
   
  }

  .tableListFile .icon-folder-white{
    background-image: url('../images/icons/folder_no_file.svg');
  }

  .tableListFile .img_folder{
    background-image: url('../images/icons/folder_no_file.svg');
  }

  .tableListFile .img_folder_has_file{
    background-image: url('../images/icons/folder_with_files.svg');
  }
  
  .tableListFile .img_docx{
  background-image: url('../images/icons/word.svg');
  }

  .tableListFile .img_doc{
    background-image: url('../images/icons/word.svg');
  }
  
  .tableListFile .img_excel{
  background-image: url('../images/icons/ex.svg');
  }

  .tableListFile .img_xlsx{
  background-image: url('../images/icons/ex.svg');
  }

  .tableListFile .img_xls{
    background-image: url('../images/icons/ex.svg');
  }

  .tableListFile .img_csv{
    background-image: url('../images/icons/ex.svg');
  }

  .tableListFile .img_pdf{
    background-image: url('../images/icons/pdf.svg');
  }

  .tableListFile .img_pptx{
    background-image: url('../images/icons/PP.svg');
  }

  .tableListFile .img_ppt{
    background-image: url('../images/icons/PP.svg');
  }

  .tableListFile .img_file_white{
    background-image: url('../images/icons/ant-design_file-outlined.svg');
  }

  .tableListFile .img_txt{
    background-image: url('../images/icons/txt.svg');
  }
  /*===================================*/
  
  .ant-collapse-content-box .btn-grid-outline {
    position: absolute;
    top: 14px;
    right: 50px;
    width: 20px;
    height: 20px;
    border: 1px solid grey;
  }

  .btn-group-style-view.active .btn-grid-outline{
    border: 1px solid #54B5FF;
  }

  .btn-group-style-view.active .btn-grid-outline svg{
    color: #54B5FF;
    font-size: 12px;
  }



  .ant-collapse-content-box .btn-list-outline {
    position: absolute;
    top: 14px;
    right: 20px;
    width: 20px;
    height: 20px;
    border: 1px solid #54B5FF;
  }

  .ant-collapse-content-box .btn-list-outline svg{
    color: #54B5FF;
    font-size: 12px;
  }

  .btn-group-style-view.active .btn-list-outline{
    border: 1px solid grey;
  }
  
  .btn-group-style-view.active .btn-list-outline svg{
    color:grey;
    font-size: 12px;
  }

.btnBack{
  width:32px;
  height: 32px;
  background: #F3F2F1;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 16px;
  cursor: pointer

}

.btnBack:hover{
  background:#eeece8
}

.headerPopup{
  align-items: center;
}
.titlePopup{
  padding: 0px 14px;
}

.list {
  padding: 8px 0px;
}

 .img_create_folder{
  width: 30px;
  height: 20px;
  background-size: 25px;
  background-image: url('../images/icons/tao_thu_muc.svg');
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer
}
/*===================================*/

.img_project {
  width: 120px;
  height: 120px;
}

.container{
  padding: 20px;
  display: block;
}
.img_project_list{
  width: 60px;
  height: 60px;
}

/*===================================*/


  /* bread crumb */
  .bread-crumb{
    font-size: 18px;
    padding: 18px 10px 10px 40px;
  }


  .ead-iframe {
    width: 100%;
    height: 100%;
  }

  .iframe-viewer {
    width: 100%;
    height: 100%;
    }