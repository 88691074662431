a {
    color: #000000;
}

a:hover{
    color: #000000;
}
.suggest-search {
    /* background-color: #b5b7bb; */
    padding: 8px 11px;
  }
  
  .title-search {
    font-size: 20px;
    line-height: 16px;
    color: #000000;
    ;
  }
  
  .search-item {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }
  
  .name-item-search {
    width: 65%;
    margin-left: 21px;
    font-size: 16px;
  }
  
  .icon-item-search{
    margin-left: 5px;
  }
  
  .date-item-search {
    width: 30%;
    float: right;
    display: flex;
    justify-content: flex-end;
  }

  .name-user-create{
      font-size: 14px;
      color: #605E5C;
  }