@for $i from 0 through 7 {
    .mb_#{$i*5} {
      margin-bottom: 0px + $i * 5 !important;
    }
  }
  
  @for $i from 0 through 7 {
    .mt_#{$i*5} {
      margin-top: 0px + $i * 5 !important;
    }
  }
  
  @for $i from 0 through 7 {
    .mr_#{$i*5} {
      margin-right: 0px + $i * 5 !important;
    }
  }
  
  @for $i from 0 through 7 {
    .ml_#{$i*5} {
      margin-left: 0px + $i * 5 !important;
    }
  }
  @for $i from 0 through 7 {
    .pl_#{$i*5} {
      padding-left: 0px + $i * 5 !important;
    }
  }
  @for $i from 0 through 7 {
    .pt_#{$i*5} {
      padding-top: 0px + $i * 5 !important;
    }
  }
  @for $i from 0 through 7 {
    .pr_#{$i*5} {
      padding-right: 0px + $i * 5 !important;
    }
  }
  @for $i from 0 through 7 {
    .pb_#{$i*5} {
      padding-bottom: 0px + $i * 5 !important;
    }
  }

  .clickImg {
    .react-calendar-timeline .rct-outer {
      overflow-y: var(--unset);
      height: var(--height) !important;
    }

    .react-calendar-timeline .rct-vertical-lines .rct-vl {
      border: var(--border);
      background: rgba(0, 0, 0, 0.0001);
      box-shadow: var(--box_shadow);
      margin-left: 2px;
      height: 100% !important;
    }
  }
  